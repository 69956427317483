import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField, Paper } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const CardsTable = ({rows, setRows}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  
    const handleCellChange = (e, id) => {
        const newRows = [...rows];
        const index = rows.findIndex(row => row.id === id);
        newRows[index].question = e.target.value;
        setRows(newRows);
    };

    const handleDelete = (id) => {
        const newRows = rows.filter(row => row.id !== id);
        setRows(newRows);
    }
    
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>
                                <TextField
                                    value={row.question}
                                    onChange={(e) => handleCellChange(e, row.id)}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell align="right" style={{ width: '10px' }}>
                                <IconButton onClick={() => handleDelete(row.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

export default CardsTable;
