import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemText, Box } from "@mui/material";
import { Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

function YtDrawer({setPanel}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setIsOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      sx={{ backgroundColor: '#124451', width: 400 }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'left', p: 1 }}>
        <img src="yt.png" alt="Logo" style={{ height: 60, width: 60 }} />
      </Box>
      <List>
        {["Dashboard", "Cards", "Users", "Logout"].map((text, index) => (
          <ListItem button key={text} onClick={() => setPanel(text)}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}><MenuIcon /></Button>
      <Drawer 
            sx={{ 
              width: 240,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
                color: '#FFFFFF',
                backgroundColor: '#124451',  // Set your color here
              },
            }}
        style={{ backgroundColor: '#3f51b5' }} // Add this line to set the background color
        variant="permanent"  anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
}

export default YtDrawer;
