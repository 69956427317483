import React, { useState, useEffect } from "react";
import CardsTable from "./CardsTable";
import CardFilters from "../components/CardFilters";
import { createGame, fetchGame } from "../api/game";
import Modal from "../components/Modal";
import CreateItemButton from "../components/CreateItemButton";
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography';
import { initialState } from "../data/state";

const CardPanel = () => {
    const [data, setData] = useState({});
    const [level, setLevel] = React.useState("Level 1");
    const [category, setCategory] = React.useState("Personal");
  
    useEffect(() => {
        const fetchAndSetData = async () => {
            try {
                const gameData = await fetchGame();
                
                if (gameData?.levels.length > 0) {
                    setData(gameData);
                } else {
                    setData(initialState);
                }
            } catch(e) {
                setData(initialState);
                console.log(e);
            }

        }
        fetchAndSetData();
    }, []);

    const getDataBasedOnFilters = () => {
        if (!data.levels) {
            return [];
        }
        const levelObj = data.levels.find(l => l.level === level);
    
        if (levelObj) {
          const categoryObj = levelObj.categories.find(c => c.category === category);
    
          if (categoryObj) {
            return categoryObj.cards;
          }
        }
    
        return [];
    }
    
    const setDataBasedOnFilters = (cards) => {
        setData(prevData => {
            const newData = JSON.parse(JSON.stringify(prevData)); // Deep copy to avoid direct mutation
            const levelObj = newData.levels.find(l => l.level === level);
        
            if (levelObj) {
                const categoryObj = levelObj.categories.find(c => c.category === category);
          
                if (categoryObj) {
                    categoryObj.cards = cards; // Update the cards within the existing cat
                } else { // Add new category
                    const newCat = {
                        category,
                        cards,
                        variant: "needs"
                    }

                    levelObj.categories.push(newCat);
                }
            }
        
            return newData; // Return new data to update state
        });
    };

    const handlePublishPress = async () => {
        try {
            const gameData = await createGame(data);
            setData(gameData);
        } catch(error) {
            console.error(error);
        }
    }

    const handleAddItem = () => {
        const newData = [{
            id: uuidv4(),
            question: 'New card',
            isAnswered: false,
            isRainChecked: false,
        },...getDataBasedOnFilters()];
        setDataBasedOnFilters(newData);
    }

    const renderControl = () => {
        const dataFiltered = getDataBasedOnFilters();
        return (
            <div style={{padding: 10}}>
                <CreateItemButton onClick={handleAddItem} />
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <div style={{margin: 10}}>
                        <Typography variant="h4">Cards</Typography>
                    </div>
                    <Modal handlePublishPress={handlePublishPress} />
                </div>
                <CardFilters 
                    level={level}
                    category={category}
                    setLevel={setLevel}
                    setCategory={setCategory}
                />
                <CardsTable
                    rows={dataFiltered}
                    setRows={setDataBasedOnFilters}
                />
            </div>
        )
    };

    return (
        <>
            {renderControl()}
        </>
    );
}

export default CardPanel;
