export const AUTH_TOKEN_ITEM_NAME = 'authTokens';

export const setAuthTokens = (tokens) => {
    // Local Storage can only store strings. So we need to convert our JSON object to a string using stringify.
    localStorage.setItem(AUTH_TOKEN_ITEM_NAME, JSON.stringify(tokens));
}

export const getTokensFromStorage = () => {
    // Retrieve the item from Local Storage and parse it back to JSON.
    const storedTokens = localStorage.getItem(AUTH_TOKEN_ITEM_NAME);
    
    if (storedTokens) {
        return JSON.parse(storedTokens).tokens;
    }
    
    return null;
}
