import React from 'react';
import { Box, Select, MenuItem, Typography } from '@mui/material';

function CardFilters({setLevel, setCategory, level, category}) {

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
      <Box width={1/2} paddingRight={2}>
        <Select
          value={level}
          onChange={handleLevelChange}
          fullWidth
          variant="outlined"
          style={{ background: '#ffffff', color: '#124451' }}
        >
          <MenuItem value="Level 1">Level 1</MenuItem>
          <MenuItem value="Level 2">Level 2</MenuItem>
          <MenuItem value="Level 3">Level 3</MenuItem>
        </Select>
      </Box>
      <Box width={1/2}>
        <Select
          value={category}
          onChange={handleCategoryChange}
          fullWidth
          variant="outlined"
          style={{ background: '#ffffff', color: '#124451' }}
        >
          <MenuItem value="Personal">Personal</MenuItem>
          <MenuItem value="Relational">Relational</MenuItem>
          <MenuItem value="Financial">Financial</MenuItem>
          <MenuItem value="Values and beliefs">Values and beliefs</MenuItem>
          <MenuItem value="Family & Friendship">Family & Friendship</MenuItem>
          <MenuItem value="Professional">Professional</MenuItem>
          <MenuItem value="Sexual">Sexual</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}

export default CardFilters;
