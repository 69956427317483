import React, { useState, useEffect } from "react";
import Typography from '@mui/material/Typography';
import { fetchUsers } from "../api/user";
import UsersTable from "./UsersTable";

const CardPanel = () => {
    const [data, setData] = useState(undefined);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
  
    useEffect(() => {
        const fetchAndSetData = async () => {
            try {
                const usersData = await fetchUsers({page, limit});

                setData(usersData);
            } catch(e) {
                console.log(e);
            }
        }
        fetchAndSetData();
    }, [page, limit]);

    const renderControl = () => {
        return (
            <div style={{padding: 10}}>
                <Typography variant="h4">Users</Typography>
                {data && <UsersTable
                    rows={data.results}
                    count={data.totalResults}
                    page={page}
                    limit={limit}
                    setPage={setPage}
                    setLimit={setLimit}
                />}
            </div>
        )
    };

    return (
        <>
            {renderControl()}
        </>
    );
}

export default CardPanel;
