import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import YtDrawer from "./YtDrawer";
import CardPanel from "./CardPanel";
import UserPane from "./UserPane";
import { getAccessToken } from "../api/auth";
import { setAuthTokens } from "../auth/tokens";

const AdminPanel = () => {
    const navigate = useNavigate();
    const [currentPanel, setCurrentPanel] = useState('Cards');

    const setPanel = (currentPanelName) => {
        setCurrentPanel(currentPanelName);
    }

    const logout = () => {
        setAuthTokens({});
        navigate('/auth');
    }

    useEffect(()=> {
        const checkAuth = async () => {
            try {
                await getAccessToken();
            } catch(e) {
                logout();
            }
        }
        checkAuth();
    }, [])

    function renderPanel(currentPanel) {
        if (currentPanel === 'Logout') {
            logout();
        }
        switch(currentPanel) {
            case 'Cards':
                return <CardPanel />
            case 'Users':
                return <UserPane />
            default:
                return <CardPanel />;
        }
    }

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <YtDrawer setPanel={setPanel} />
            <div style={{ width: '80%', height: '100%', overflowY: 'auto' }}>
                {renderPanel(currentPanel)}
            </div>
        </div>
    );
}

export default AdminPanel;
