import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const buttonStyle = {
  position: 'fixed',
  bottom: '2rem',
  right: '2rem',
  background: '#124451'
};

function CreateItemButton({ onClick }) {
  return (
    <Fab
      color="primary"
      aria-label="add"
      style={buttonStyle}
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  );
}

export default CreateItemButton;
