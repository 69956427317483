import axios from 'axios';
import { getTokensFromStorage, setAuthTokens } from '../auth/tokens';

axios.interceptors.response.use(
  function(response) {
    // If the request succeeds, this block will be executed
    // The data can be directly returned
    return response;
  },
  function(error) {
    // If the request fails, this block will be executed
    // You can do something with the error here
    // For instance, handle specific status codes

    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Handle 401 error code
          console.log("Unauthorized access.");
          logout();
          window.location.href = "/auth";
          break;
        default:
          // Handle other status codes
          console.log("An error occurred: ", error.message);
      }
    } else {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
      console.log(error.request);
    }

    return Promise.reject(error);
  }
);

// set up axios filter to redirect to auth on a 401
export const getAccessToken = async () => {
    const tokens = getTokensFromStorage();

    if (!tokens) {
        throw new Error('No tokens found');
    }

    if (new Date(tokens.access.expires) < new Date()) {
        if (new Date(tokens.refresh.expires) > new Date()) {
            try {
                const authTokensRefreshed = await refreshAuthTokens(tokens.refresh.token);
                setAuthTokens(authTokensRefreshed);
                return authTokensRefreshed.tokens.access.token;
            } catch (error) {
                throw error;
            }
        } else {
            throw new Error('Auth tokens expired');
        }
    } else {
        return tokens.access.token
    }
}

export const refreshAuthTokens = async (refreshToken) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/refresh-tokens`, { refreshToken });
        return response.data;
    } catch (error) {
        console.error('Failed to refresh auth tokens:', error);
        throw error;
    }
}

export const login = async (email, password) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/login`, { email, password });
        setAuthTokens(response.data);
        return response.data;
    } catch (error) {
        console.error('Failed to login:', error);
        throw error;
    }
}

export const logout = async () => {
    setAuthTokens({});
}

export function authHeader(token) {
    return {
      headers: {
        authorization: `Bearer ${token}`
      }
    };
  }
  