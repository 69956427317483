import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import AdminPanel from './layouts/AdminPanel';
import AuthScreen from './layouts/AuthScreen';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/auth" element={<AuthScreen />} />
        <Route path="/" element={<AdminPanel />} />
      </Routes>
    </Router>
  );
}

export default App;
