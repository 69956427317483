import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/system";

const CustomButton = styled(Button)({
  margin: 10,
  backgroundColor: "#F5AAA3",
  color: "white",
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#F5AAA3",
  },
});

function Modal({handlePublishPress}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleYesPress = () => {
    setOpen(false);
    handlePublishPress();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <CustomButton variant="contained" onClick={handleClickOpen}>
        Publish
      </CustomButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Publish Changes"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you certain you wish to proceed with publishing the modifications?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleYesPress} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Modal;
