import { v4 as uuidv4 } from 'uuid';

export const initialState = {
  levels: [
    {
      level: 'Level 1',
      description: '50/100 question available',
      categories: [
        {
          category: 'Personal',
          variant: 'needs',
          cards: [
            {
              id: uuidv4(),
              question:
                'Can you describe a situation where you had to express a need to a superior at work? What was the outcome?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Financial',
          variant: 'search',
          cards: [
            {
              id: uuidv4(),
              question:
                'What is one hobby or interest you have that might surprise people?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Values and beliefs',
          variant: 'location',
          cards: [
            {
              id: uuidv4(),
              question:
                'What is your partner’s favorite way to spend an evening?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Relational',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Family & Friendship',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Professional',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Sexual',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
      ],
    },
    {
      level: 'Level 2',
      description: '1/100 question available',
      categories: [
        {
          category: 'Personal',
          variant: 'needs',
          cards: [
            {
              id: uuidv4(),
              question:
                'Can you describe a situation where you had to express a need to a superior at work? What was the outcome?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Financial',
          variant: 'search',
          cards: [
            {
              id: uuidv4(),
              question:
                'What is one hobby or interest you have that might surprise people?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Values and beliefs',
          variant: 'location',
          cards: [
            {
              id: uuidv4(),
              question:
                'What is your partner’s favorite way to spend an evening?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Relational',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Family & Friendship',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Professional',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Sexual',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
      ],
    },
    {
      level: 'Level 3',
      description: '1/100 question available',
      categories: [
        {
          category: 'Personal',
          variant: 'needs',
          cards: [
            {
              id: uuidv4(),
              question:
                'Can you describe a situation where you had to express a need to a superior at work? What was the outcome?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Financial',
          variant: 'search',
          cards: [
            {
              id: uuidv4(),
              question:
                'What is one hobby or interest you have that might surprise people?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Values and beliefs',
          variant: 'location',
          cards: [
            {
              id: uuidv4(),
              question:
                'What is your partner’s favorite way to spend an evening?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Relational',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Family & Friendship',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Professional',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
        {
          category: 'Sexual',
          variant: 'chilli',
          cards: [
            {
              id: uuidv4(),
              question:
                'What’s something you’ve always wanted to do together but haven’t had the chance yet?',
              isAnswered: false,
              isRainChecked: false,
            },
          ],
        },
      ],
    },
  ],
};