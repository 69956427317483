import axios from 'axios';
import { authHeader, getAccessToken } from './auth';

export const createGame = async (game) => {
  try {
    const accessToken = await getAccessToken();
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/games/creategame`, game, authHeader(accessToken));
    return response.data;
  } catch (error) {
      console.error('Failed to login:', error);
      throw error;
  }
}

export const fetchGame = async (game) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/games/game`);

    return response.data;
  } catch (error) {
      console.error('Failed to fetch game:', error);
      throw error;
  }
};
