import axios from 'axios';
import { authHeader, getAccessToken } from './auth';

export const fetchUsers = async ({page, limit = 10}) => {
  try {
    const accessToken = await getAccessToken();
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/users`,  
      {...authHeader(accessToken), 
        params: { page, limit, role: 'user' },
      }
    );
    
    return response.data;
  } catch (error) {
      console.error('Failed to fetch users:', error);
      throw error;
  }
};
